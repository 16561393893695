import React from 'react'
import { compose } from 'redux'
import { reduxForm, Field, FieldArray, formValueSelector } from 'redux-form'
import { Link } from 'react-router-dom'
import { useMediaQuery } from 'react-responsive'

import { FormTitle, FormViewWrapper, FormWrapper } from './styles/Form.style'
import FormInput from '../input/FormInput'
import SelectFormInput from '../input/SelectFormInput'
import FormEditor from './Validation/FormEditorValidator'
import UploadInput from '../input/UploadInput'
import DestinationInput from '../input/DestinationInput'
import { required, validDestination } from './Validation/validation'
import { LineDivider } from '../view/MessageView'
import ReturnIcon from '../../static/return-icon'
import styled from '@emotion/styled'
import CancelIcon from '../../static/cancel-icon'
import {
  useHistory,
  useLocation
} from 'react-router-dom/cjs/react-router-dom.min'
import CheckmarkGreenIcon from '../../static/checkmark-green-icon.js'
import EditIcon from '../../static/edit-icon'
import { reportKindOptions } from '../../utils/constants.js'
import { useSelector } from 'react-redux'
import CheckboxInput from '../input/CheckboxInput.js'

export const Container = styled('div')`
  .mce-container,
  .mce-container *,
  .mce-widget,
  .mce-widget *,
  .mce-reset {
    border-radius: 8px;
  }
  .mce-container-body {
    background-color: #eaeced;
  }
  .mce-btn-group:not(:first-child) {
    border: none;
    background-color: #eaeced;
  }
  .ant-pagination {
    margin-top: 7px;
  }
  a:hover {
    font-weight: 600;
    svg > circle {
      stroke: #eaeced;
      fill: #776aae;
    }
    svg > path {
      stroke: #fff;
    }
  }
`

export const CancelButton = styled('button')`
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 4px;
  cursor: pointer;
  border-radius: 8px;
  border: none;
  background-color: #f69e9e;
  color: #fff;
  padding: 14.5px 24.2px;
  &:hover {
    background-color: #f69e9e7e;
    svg > circle {
      fill: none;
    }
  }
  @media (max-width: 767px) {
    width: 100%;
    justify-content: center;
  }
`
export const SendButton = styled('button')`
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 4px;
  cursor: pointer;
  border-radius: 8px;
  border: none;
  background-color: #776aae;
  color: #fff;
  padding: 14.5px 24.2px;
  &:hover {
    background-color: #776aae7e;
    svg > circle {
      fill: none;
    }
  }
  @media (max-width: 767px) {
    width: 100%;
    justify-content: center;
  }
`
export const SaveDraftButton = styled('button')`
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 12px 20px;
  border-radius: 8px;
  border: none;
  font-weight: 600;
  color: #484848;
  background-color: #fff;
  cursor: pointer;
  &:disabled {
    cursor: not-allowed;
    border: 1px solid #484848;
    background-color: transparent;
  }
  &:hover {
    background-color: #4848487e;
  }
  &:disabled:hover {
    background-color: transparent;
  }
  @media (max-width: 767px) {
    width: 100%;
    justify-content: center;
  }
`

export const ButtonsContainer = styled('div')`
  display: flex;
  justify-content: flex-end;
  margin: 1em 0;
  gap: 16px;
  @media (max-width: 767px) {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column-reverse;
  }
`
const ReportsForm = props => {
  const {
    users,
    audiences,
    onSubmit,
    handleSubmit,
    submit,
    deleteReportFileRequest,
    edit,
    initialValues,
    setDraft,
    isFetching,
    isFormValid
  } = props
  const isPhone = useMediaQuery({ query: '(max-width: 767px)' })
  const [, updateState] = React.useState({})
  const forceUpdate = React.useCallback(async () => updateState({}), [])
  const state = useSelector(state => state)
  const formValues = formValueSelector('reportsForm')
  const files = formValues(state, 'files')
  const history = useHistory()
  const location = useLocation()
  const kind = location?.state?.kind

  const reportKind = reportKindOptions.find(item => item.value === kind)
  return (
    <FormViewWrapper isPhone={isPhone}>
      <Container style={{ marginBottom: '16px' }}>
        <Link
          style={{
            display: 'flex',
            color: '#484848',
            alignItems: 'center',
            gap: '4px',
            width: 'fit-content'
          }}
          to={reportKind.path}
        >
          {' '}
          <ReturnIcon />
          Go back to {reportKind.label}s list{' '}
        </Link>
      </Container>
      <FormTitle style={{ color: '#484848' }}>
        {edit ? `Edit ${reportKind.label}` : `Create new ${reportKind.label}`}
      </FormTitle>
      <LineDivider />
      <FormWrapper onFinish={handleSubmit(onSubmit)}>
        <span
          style={{
            display: 'block',
            fontSize: '1.5em',
            color: '#484848'
          }}
        >
          {' '}
          Report Title
        </span>
        <Field
          name='title'
          component={FormInput}
          placeholder='Title'
          required
          validate={[required]}
        />
        <div style={{ display: 'flex' }}>
          <Field
            name='private'
            component={CheckboxInput}
          />
          <span>Private Report</span>
        </div>

        <span
          style={{
            display: 'block',
            fontSize: '1.5em',
            color: '#484848'
          }}
        >
          {' '}
          Report Kind
        </span>
        <Field
          name='kind'
          options={reportKindOptions}
          component={SelectFormInput}
          validate={[required]}
        />
        <span
          style={{
            display: 'block',
            fontSize: '1.5em',
            color: '#484848'
          }}
        >
          {' '}
          Report message{' '}
        </span>
        <span
          style={{
            color: '#484848'
          }}
        >
          {' '}
          Here you can create and edit the content of the report.{' '}
        </span>
        <LineDivider style={{ marginBottom: '7px' }} />
        <Container>
          <Field
            required
            validate={[required]}
            name='message'
            component={FormEditor}
          />
        </Container>
        <span
          style={{
            display: 'block',
            fontSize: '1.5em',
            color: '#484848'
          }}
        >
          {' '}
          Attachments{' '}
        </span>
        <span
          style={{
            color: '#484848'
          }}
        >
          {' '}
          Add the files that will be part of your report. Either drag the file
          to the area or click at the area to upload.{' '}
        </span>
        <LineDivider />
        <FieldArray
          name='files'
          isDrag
          component={UploadInput}
          multiple
          deleteItemFileRequest={deleteReportFileRequest}
          edit={edit}
          itemId={initialValues && initialValues.id}
          forceUpdate={forceUpdate}
          fieldArrayValue={files}
        />
        <p
          style={{
            color: '#666',
            fontStyle: 'italic',
            fontSize: '12px',
            marginTop: '16px'
          }}
        >
          .PDF only
        </p>
        <span
          style={{
            display: 'block',
            fontSize: '1.5em',
            color: '#484848'
          }}
        >
          {' '}
          Destination{' '}
        </span>
        <span
          style={{
            color: '#484848'
          }}
        >
          {' '}
          Select the audiences, investors or users to which your report will be
          sent.{' '}
        </span>
        <LineDivider style={{ marginBottom: '7px' }} />
        <Container>
          <FieldArray
            name='destinations_attributes'
            component={DestinationInput}
            users={users}
            audiences={audiences}
            required
            validate={[required, validDestination]}
            isPhone={isPhone}
            forceUpdate={forceUpdate}
          />
        </Container>
      </FormWrapper>
      <LineDivider />
      <ButtonsContainer>
        <CancelButton onClick={() => history.push('/')}>
          <CancelIcon />
          Cancel
        </CancelButton>
        {((edit && initialValues.draft) || !edit) && (
          <SaveDraftButton
            loading={isFetching}
            disabled={isFetching || !isFormValid}
            onMouseEnter={() => setDraft(true)}
            onMouseLeave={() => setDraft(false)}
            onClick={() => submit('reportsForm')}
          >
            <EditIcon />
            Save as draft
          </SaveDraftButton>
        )}{' '}
        <SendButton
          loading={isFetching}
          disabled={isFetching || !isFormValid}
          onClick={() => submit('reportsForm')}
        >
          <CheckmarkGreenIcon />
          Send
        </SendButton>
      </ButtonsContainer>
    </FormViewWrapper>
  )
}

const enhance = compose(reduxForm({ form: 'reportsForm' }))

export default enhance(ReportsForm)
