import React from 'react'
import { LockFilled } from '@ant-design/icons'

import http from '../../utils/http'
import { AlternativeSpinner as LoadingSpinner } from '../loading/Loading'
import { SubmitButton } from '../form/styles/LoginForm.style'
import {
  Container,
  ContainerTitle,
  InnerContainer
} from './styles/Views.styles'
import InputComponent from '../input/StyledInput'
import { setQueryParams } from '../../utils/functions'

const createRegisteredDevice = code => {
  const params = {
    data: {
      type: 'registered_devices',
      attributes: {
        device_id: code
      }
    }
  }
  return http
    .post('/registered_devices', params)
    .then(res => res)
    .catch(err => err.response)
}

const getDevicesList = device_id => {
  const params = setQueryParams({
    'q[device_id_eq]': device_id
  })
  return http
    .get(`/registered_devices?${params}`)
    .then(res => res)
    .catch(err => err.response)
}
const confirmCode = props => {
  const { email_code, mobile_code } = props
  const params = {
    data: {
      type: 'registered_devices',
      attributes: {
        device_id: email_code
      }
    }
  }
  return http
    .get(
      `/registered_devices/confirm?email_code=${email_code}&mobile_code=${mobile_code}`,
      params
    )
    .then(res => res)
    .catch(err => err.response)
}

const DeviceAuthView = props => {
  const [authStatus, setAuthStatus] = React.useState('waiting')
  const [emailCodeInputValue, setEmailCodeInputValue] = React.useState('')
  const [mobileCodeInputValue, setMobileCodeInputValue] = React.useState('')
  const [errors, setErrors] = React.useState([])
  const { verifyBrowserSuccess, updateCredentialsRequest, deviceId, setStage } = props
  const handleUnauthorized = async response => {
    updateCredentialsRequest(response.headers)
    // setAuthStatus('waiting')
    const createResponse = await createRegisteredDevice(deviceId)
    if (createResponse.status === 201) {
      updateCredentialsRequest(response.headers)
      setAuthStatus('waiting')
    } else if (createResponse.status === 422) {
      setAuthStatus('accepted')
      updateCredentialsRequest(createResponse.headers)
      setTimeout(() => {
        verifyBrowserSuccess({ id: deviceId })
      }, 2000)
    } else if (createResponse.status === 401) {
      setStage(0)
    }
  }
  React.useEffect(async () => {
    setAuthStatus('loading')
    const response = await getDevicesList(deviceId)
    if (response.status === 200) {
      const { data: { data } } = response
      if (!data || data.length === 0) {
        handleUnauthorized(response)
      } else {
        setAuthStatus('accepted')
        updateCredentialsRequest(response.headers)
        setTimeout(() => {
          verifyBrowserSuccess({ id: deviceId })
        }, 2000)
      }
    } else if (response.status === 401) {
      handleUnauthorized(response)
    }
  }, [])
  const confirmCodeRequest = async code => {
    setAuthStatus('loading')
    const response = await confirmCode(code)
    if (response.status === 200) {
      setAuthStatus('accepted')
      setTimeout(() => {
        verifyBrowserSuccess({ id: deviceId })
        updateCredentialsRequest(response.headers)
      }, 2000)
    } else {
      const errorMsg = 'Invalid Code'
      !errors.includes(errorMsg) && setErrors(errors.concat(errorMsg))
      setAuthStatus('waiting')
    }
  }
  return (
    <Container>
      <InnerContainer>
        {authStatus === 'loading' ? (
          <>
            <ContainerTitle> Verifying Device </ContainerTitle>
            <LoadingSpinner />
          </>
        ) : authStatus === 'waiting' ? (
          <>
            <ContainerTitle>Device authorization</ContainerTitle>
            <span style={{ color: '#484848' }}>
              {' '}
              Two messages with two codes were sent to you: one to your email
              and one to your cellphone number.
              <br />
              Please enter those codes in the fields below to authorize the
              access for this browser.
            </span>
            <InputComponent
              value={emailCodeInputValue}
              onChange={e => setEmailCodeInputValue(e.target.value)}
              prefix={<LockFilled />}
              placeholder='Insert Email Code here'
            />
            <InputComponent
              value={mobileCodeInputValue}
              onChange={e => setMobileCodeInputValue(e.target.value)}
              prefix={<LockFilled />}
              placeholder='Insert Mobile code here'
            />
            {errors.map(item => (
              <span key={item} style={{ color: 'red' }}>
                {' '}
                {item}{' '}
              </span>
            ))}
            <SubmitButton
              disabled={!emailCodeInputValue || !mobileCodeInputValue}
              onClick={() => {
                confirmCodeRequest({
                  email_code: emailCodeInputValue,
                  mobile_code: mobileCodeInputValue
                })
              }}
            >
              Verify Code
            </SubmitButton>
          </>
        ) : authStatus === 'verifying' ? (
          <>
            <ContainerTitle> Verifying Codes </ContainerTitle>
            <LoadingSpinner />
          </>
        ) : authStatus === 'accepted' ? (
          <>
            <ContainerTitle> Device authorized </ContainerTitle>
            <LoadingSpinner />
            <span>You will be redirected soon</span>
          </>
        ) : null}
      </InnerContainer>
    </Container>
  )
}

export default DeviceAuthView
