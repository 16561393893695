/* eslint-disable camelcase */
import { put, call, takeLatest, all } from 'redux-saga/effects'
import * as actions from './index'
import * as services from './services'

import { updateCredentialsRequest } from '../auth/actions'
import { reportKindOptions } from '../../utils/constants'
import {
  rejectPromiseAction,
  resolvePromiseAction
} from 'redux-saga-promise-actions'

export function * fetchReports (action) {
  const { payload } = action
  try {
    const response = yield call(services.fetchReports, payload)
    if (response.status === 200) {
      const { data: { data, meta: { total_count } }, headers } = response
      const reportsData = data.map(item => ({
        ...item.attributes,
        id: item.id
      }))
      yield put(actions.updateReportPagination({ total: total_count }))
      yield put(actions.fetchReports.success(reportsData))
      resolvePromiseAction(action, response)
      yield put(updateCredentialsRequest(headers))
    }
  } catch (error) {
    console.error(error)
    rejectPromiseAction(action, error)
    yield put(actions.fetchReports.failure(error))
  }
}

export function * createReport ({ payload }) {
  try {
    const { attributes, history } = payload
    let formData = new FormData()
    formData.append('data[type]', 'reports')
    formData.append('data[attributes][title]', attributes['title'])
    formData.append('data[attributes][message]', attributes['message'] || '')
    formData.append('data[attributes][draft]', attributes['draft'])
    formData.append('data[attributes][kind]', attributes['kind'])
    formData.append('data[attributes][private]', attributes['private'])
    attributes.destinations_attributes.forEach((attribute, index) => {
      attribute.id &&
        formData.append(
          `data[attributes][destinations_attributes][${index}][id]`,
          attribute.id
        )
      attribute._destroy &&
        formData.append(
          `data[attributes][destinations_attributes][${index}][_destroy]`,
          true
        )
      formData.append(
        `data[attributes][destinations_attributes][${index}][destinatable_type]`,
        attribute.destinatable_type
      )
      formData.append(
        `data[attributes][destinations_attributes][${index}][destinatable_id]`,
        attribute.destinatable_id
      )
    })
    if (attributes?.files?.length) {
      attributes.files.forEach((item, index) => {
        formData.append(`data[attributes][files][${index}][file]`, item.file)
      })
    }

    const response = yield call(services.createReport, formData)
    if (response.status === 201) {
      yield put(actions.createReportSuccess())
      yield put(updateCredentialsRequest())
      const kind = reportKindOptions.find(
        item => item.value === attributes.kind
      )
      history.push(kind.path)
    }
  } catch (error) {
    console.error(error)
    yield put(actions.createReportFailure(error))
  }
}

export function * updateReport ({ payload }) {
  try {
    const { attributes, history, id } = payload
    let formData = new FormData()
    formData.append('data[id]', id)
    formData.append('data[type]', 'reports')
    formData.append('data[attributes][title]', attributes['title'])
    formData.append('data[attributes][message]', attributes['message'])
    formData.append('data[attributes][draft]', attributes['draft'])
    formData.append('data[attributes][kind]', attributes['kind'])
    formData.append('data[attributes][private]', attributes['private'])
    attributes.destinations_attributes.forEach((attribute, index) => {
      attribute.id &&
        formData.append(
          `data[attributes][destinations_attributes][${index}][id]`,
          attribute.id
        )
      attribute._destroy &&
        formData.append(
          `data[attributes][destinations_attributes][${index}][_destroy]`,
          true
        )
      formData.append(
        `data[attributes][destinations_attributes][${index}][destinatable_type]`,
        attribute.destinatable_type
      )
      formData.append(
        `data[attributes][destinations_attributes][${index}][destinatable_id]`,
        attribute.destinatable_id
      )
    })
    if (attributes?.files?.length) {
      attributes.files.forEach((item, index) => {
        if (item.file) {
          formData.append(`data[attributes][files][${index}][file]`, item.file)
        }
        if (item._destroy) {
          formData.append(`data[attributes][files][${index}][id]`, item.uid)
          formData.append(
            `data[attributes][files][${index}][_destroy]`,
            item._destroy
          )
        }
      })
    }

    const response = yield call(services.updateReport, { params: formData, id })
    if (response && response.status === 200) {
      yield put(actions.updateReportSuccess())
      yield put(updateCredentialsRequest(response.headers))
      const kind = reportKindOptions.find(
        item => item.value === attributes.kind
      )
      history.push(kind.path)
    }
  } catch (error) {
    console.error(error)
    yield put(actions.createReportFailure(error))
  }
}

export function * deleteReport (action) {
  const { payload } = action
  try {
    const response = yield call(services.deleteReport, { id: payload })
    resolvePromiseAction(action, response)
    yield put(updateCredentialsRequest(response.headers))
  } catch (e) {
    rejectPromiseAction(action, e)
    console.error(e)
  }
}

export function * deleteReportFile ({ payload }) {
  try {
    const { itemId, fileId, fields, fileIndex } = payload
    const response = yield call(services.deleteReportFile, {
      reportId: itemId,
      fileId
    })
    if (response.status === 204) {
      fields.remove(fileIndex)
      yield put(updateCredentialsRequest(response.headers))
    }
  } catch (e) {
    console.error(e)
  }
}

export function * toggleFavoriteReport (action) {
  const { payload } = action
  try {
    const response = yield call(services.toggleFavoriteReport, payload)
    if (response.status === 201 || response.status === 204) {
      yield put(updateCredentialsRequest(response.headers))
      resolvePromiseAction(action, response)
    }
  } catch (err) {
    console.error(err)
  }
}

export function * watchCreateReports () {
  yield takeLatest(actions.createReportRequest, createReport)
}

export function * watchFetchReports () {
  yield takeLatest(actions.fetchReports.request, fetchReports)
}

export function * watchUpdateReport () {
  yield takeLatest(actions.updateReportRequest, updateReport)
}

export function * watchDeleteReport () {
  yield takeLatest(actions.deleteReport.request, deleteReport)
}
export function * watchDeleteReportFile () {
  yield takeLatest(actions.deleteReportFileRequest, deleteReportFile)
}

export function * watchToggleFavoriteReport () {
  yield takeLatest(actions.toggleFavoriteReport.request, toggleFavoriteReport)
}

export function * reportsSagas () {
  yield all([
    watchFetchReports(),
    watchCreateReports(),
    watchUpdateReport(),
    watchDeleteReport(),
    watchDeleteReportFile(),
    watchToggleFavoriteReport()
  ])
}
