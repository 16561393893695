import React from 'react'
import { connect, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

import {
  fetchReports,
  deleteReport,
  updateReportPagination,
  toggleFavoriteReport
} from '../../store/reports'

import ReportsView from '../../components/view/ReportsView'
import { compact, filter, find, isEmpty, map } from 'lodash'
import { fetchAudiences } from '../../store/audiences'

const mapStateToProps = ({ reports, auth }) => ({
  reportItems: reports.items,
  loadingReports: reports.isFetching,
  userProfiles: auth.user.profiles,
  pagination: reports.pagination,
  user_id: auth.user.id
})

const mapDispatchToProps = {
  fetchReportsRequest: fetchReports.request,
  deleteReportRequest: deleteReport.request,
  updateReportPagination,
  toggleFavoriteReport: toggleFavoriteReport.request
}

const ReportsContainer = props => {
  const { fetchReportsRequest, reportItems, ...rest } = props
  const [permissions_data, setPermissionsData] = React.useState([])
  const history = useHistory()
  const dispatch = useDispatch()
  const fetchReportsMethod = async (params) => {
    const res = await dispatch(
      fetchReports.request({
        ...params,
        'include': 'destinations'
      })
    )
    const { data: { included } } = res
    const audience_destinations = filter(included, incl => incl.type === 'destinations' && incl.attributes.destinatable_type === 'Audience')

    if (!isEmpty(audience_destinations)) {
      const audience_res = await dispatch(
        fetchAudiences.request({
          filters: {
            'q[id_in]': audience_destinations.map(item => item.attributes.destinatable_id),
            'q[audience_users_user_id_eq]': rest.user_id,
            'include': 'audience_users'
          }
        })
      )
      const { data: { included } } = audience_res
      const permissions = compact(map(audience_destinations, ad => {
        const related_included = find(included, incl => incl.attributes.audience_id.toString() === ad.attributes.destinatable_id.toString() && incl.attributes.user_id.toString() === rest.user_id.toString())
        if (related_included) {
          return ({
            report_id: ad.attributes.receivable_id,
            ...related_included.attributes
          })
        }
        return null
      }))
      setPermissionsData(permissions)
    }
  }
  React.useEffect(() => {
    fetchReportsMethod({
      'q[kind_eq]': props.kind || 'reports',
      'page[number]': 1,
      'page[size]': props.pagination.pageSize,
      'q[s]': 'created_at desc'
    })
  }, [])
  return (
    <ReportsView
      {...rest}
      fetchReportRequest={fetchReportsMethod}
      history={history}
      data={reportItems}
      permissions_data={permissions_data}
    />
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(ReportsContainer)
