import React from 'react'
import { compose } from 'redux'
import { reduxForm, Field, FieldArray, FormSection } from 'redux-form'
import { Link } from 'react-router-dom'
import { Checkbox, Button, Input, Pagination } from 'antd'
import { useMediaQuery } from 'react-responsive'
import { SearchOutlined } from '@ant-design/icons'

import {
  FormViewWrapper,
  FormWrapper,
  SelectUsersWrapper,
  FormButtonsArea,
  FormTitle,
  UserAttr,
  UserCard
} from './styles/Form.style'
import FormInput from '../input/FormInput'
import TextAreaInput from '../input/TextAreaInput'
import { required } from './Validation/validation'

import ReturnIcon from '../../static/return-icon'
import { LineDivider } from '../view/MessageView'
import styled from '@emotion/styled'
import UserIcon from '../../static/user-icon'
import MailIcon from '../../static/mail-icon'
import PhoneIcon from '../../static/phone-icon'
import DocumentIcon from '../../static/document-icon'
import { find, findIndex, isEmpty } from 'lodash'
import CheckboxInput from '../input/CheckboxInput'

const Container = styled('div')`
  .ant-select-selector {
    background-color: transparent;
    border: none !important;
    span {
      color: #484848;
      font-family: 'Montserrat';
    }
  }
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    background-color: #776aae9e !important;
  }
  .ant-select-focused:not(.ant-select-disabled):hover .ant-select-selector,
  .ant-select-focused:not(.ant-select-disabled) .ant-select-selector {
    box-shadow: none;
  }
  .ant-select {
    .ant-select-arrow {
      > span > svg > path {
        fill: #484848;
      }
    }
  }
  .ant-collapse-content-box div > div {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
  }
  .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
    border: none;
  }
  .ant-pagination {
    margin-top: 0px;
  }
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border: none;
    background: transparent;
  }
  .ant-pagination-prev .ant-pagination-item-link,
  .ant-pagination-next .ant-pagination-item-link {
    background: transparent;
    border: none;
  }
  .ant-checkbox {
    &:hover {
      border: 1px solid #484848;
    }
  }
  a:hover {
    font-weight: 600;
    svg > circle {
      stroke: #eaeced;
      fill: #776aae;
    }
    svg > path {
      stroke: #fff;
    }
  }
`
const SelectUsersComponent = ({
  users,
  fields,
  fetchUsersRequest,
  updateUsersPagination,
  isPhone
}) => {
  const { pagination } = users
  const [nameFilter, setNameFilter] = React.useState('')
  const values = fields.getAll()
  const handleSizeChange = (current, size) => {
    updateUsersPagination({ current, pageSize: size })
    fetchUsersRequest({
      filters: {
        'q[name_cont]': nameFilter,
        'q[profiles_role_in]': 'investor,assessor'
      }
    })
  }
  return (
    <>
      <Input
        placeholder='Search users by name'
        value={nameFilter}
        onChange={e => setNameFilter(e.target.value)}
        style={{ width: isPhone ? '70%' : '30%' }}
        suffix={
          <SearchOutlined
            onClick={() => {
              fetchUsersRequest({
                filters: {
                  'q[name_cont]': nameFilter,
                  'q[profiles_role_in]': 'investor,assessor'
                }
              })
            }}
          />
        }
      />

      <SelectUsersWrapper isPhone={isPhone}>
        {users.items
          .filter(
            item =>
              (item.profiles && item.profiles.includes('investor')) ||
              (item.profiles && item.profiles.includes('assessor'))
          )
          .map((user) => {
            const current_audience_user = find(values, value => value.user_id.toString() === user.id.toString())
            let field_index = findIndex(values, item => item.user_id.toString() === user.id && !item._destroy)
            fields.forEach((item, index) => {
              const current_field = fields.get(index)
              if (current_field && current_field.user_id.toString() === user.id) {
                field_index = index
              }
            })
            return (
              <UserCard
                style={{ display: 'flex', flexDirection: 'column' }}
                key={user.id}
              >
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    position: 'relative'
                  }}
                >
                  <Checkbox
                    checked={values && current_audience_user && !current_audience_user?._destroy}
                    style={{ position: 'absolute', top: '0' }}
                    onChange={e => {
                      const checked = e.target.checked
                      if (checked) {
                        if (!isEmpty(current_audience_user?.id)) {
                          fields.remove(field_index)
                          fields.insert(field_index, { ...current_audience_user, _destroy: false })
                        } else {
                          fields.push({ user_id: user.id })
                        }
                      } else {
                        if (!isEmpty(current_audience_user?.id)) {
                          fields.remove(field_index)
                          fields.insert(field_index, { ...current_audience_user, _destroy: true, allow_read_private: false })
                        } else {
                          const getIndex = values.findIndex(
                            value => value.user_id === user.id
                          )
                          fields.remove(getIndex)
                        }
                      }
                    }}
                  />
                  <div style={{ display: 'inline-grid' }}>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center'
                      }}
                    >
                      <UserIcon />
                      <span
                        style={{
                          fontSize: isPhone ? '0.8em' : '1em',
                          fontWeight: 'bold',
                          textAlign: 'center',
                          color: '#484848'
                        }}
                      >
                        {' '}
                        {user.name}{' '}
                      </span>
                    </div>
                    <FormSection name={`audience_users_attributes[${field_index}]`}>
                      <UserAttr>
                        <Field
                          disabled={!current_audience_user || current_audience_user?._destroy}
                          name='allow_read_private'
                          component={CheckboxInput}
                        />
                        <span style={{ marginLeft: '0.5em', color: '#484848' }}>Can see private report</span>
                      </UserAttr>
                    </FormSection>
                    <LineDivider style={{ marginBottom: '7px' }} />
                    <UserAttr isPhone={isPhone}>
                      <MailIcon />
                      <span style={{ marginLeft: '0.5em', color: '#484848' }}>
                        {' '}
                        {user.email}{' '}
                      </span>
                    </UserAttr>
                    {user.phone && (
                      <UserAttr isPhone={isPhone}>
                        <PhoneIcon />
                        <span style={{ marginLeft: '0.5em', color: '#484848' }}>
                          {' '}
                          {user.phone}{' '}
                        </span>
                      </UserAttr>
                    )}
                    {(user.document_type || user.document_number) && (
                      <UserAttr isPhone={isPhone}>
                        <DocumentIcon />
                        <span style={{ marginLeft: '0.5em' }}>
                          <span
                            style={{
                              textTransform: 'uppercase',
                              color: '#484848'
                            }}
                          >
                            {user?.document_type}:{' '}
                          </span>
                          <span style={{ color: '#484848' }}>
                            {user?.document_number}
                          </span>
                        </span>
                      </UserAttr>
                    )}
                  </div>
                </div>
              </UserCard>
            )
          })}
      </SelectUsersWrapper>
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          margin: '1em 0'
        }}
      >
        <Container>
          <Pagination
            {...pagination}
            onShowSizeChange={handleSizeChange}
            onChange={handleSizeChange}
          />
        </Container>
      </div>
    </>
  )
}

const AudiencesForm = ({
  users,
  formValues = {},
  handleSubmit,
  onSubmit,
  submit,
  edit,
  fetchUsersRequest,
  updateUsersPagination,
  isFormValid,
  isFetching
}) => {
  const isPhone = useMediaQuery({ query: '(max-width: 767px)' })
  const audienceUsers = formValues['audience_users']
  return (
    <FormViewWrapper>
      <Container style={{ marginBottom: '16px' }}>
        <Link
          style={{
            display: 'flex',
            color: '#484848',
            alignItems: 'center',
            gap: '4px',
            width: 'fit-content'
          }}
          to='/audiences'
        >
          {' '}
          <ReturnIcon />
          Go back to audiences{' '}
        </Link>
      </Container>
      <FormTitle style={{ color: '#484848' }}>
        {edit ? 'Edit an audience' : 'Create an audience'}
      </FormTitle>
      <LineDivider style={{ marginBottom: '7px' }} />
      <FormWrapper onFinish={handleSubmit(onSubmit)}>
        <Field
          name='name'
          component={FormInput}
          placeholder='Audience name'
          required
          validate={[required]}
        />
        <Field
          name='description'
          component={TextAreaInput}
          placeholder='Audience description'
        />
        <span
          style={{
            display: 'block',
            fontSize: '1.5em',
            fontWeight: 600,
            color: '#484848',
            marginTop: '16px'
          }}
        >
          {' '}
          Audience members
        </span>
        <span
          style={{
            color: '#484848'
          }}
        >
          {' '}
          Select the audiences, investors or users to which your position
          statement will be sent.{' '}
        </span>
        <LineDivider style={{ marginBottom: '7px' }} />
        <FieldArray
          name='audience_users_attributes'
          component={SelectUsersComponent}
          users={users}
          fetchUsersRequest={fetchUsersRequest}
          audienceUsers={audienceUsers}
          updateUsersPagination={updateUsersPagination}
          required
          validate={[required]}
          isPhone={isPhone}
        />
      </FormWrapper>
      <LineDivider />
      <FormButtonsArea>
        <Button
          disabled={!isFormValid || isFetching}
          loading={isFetching}
          onClick={() => submit('audiencesForm')}
          type='submit'
        >
          Save Audience
        </Button>
      </FormButtonsArea>
    </FormViewWrapper>
  )
}

const enhance = compose(reduxForm({ form: 'audiencesForm' }))

export default enhance(AudiencesForm)
