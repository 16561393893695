/* eslint-disable camelcase */
import React from 'react'
import { connect, useDispatch } from 'react-redux'
import { useParams, useHistory } from 'react-router-dom'
import { isEmpty, map } from 'lodash'
import { getFormValues, isValid } from 'redux-form'

import AudienceForm from '../../components/form/AudiencesForm'
import { updateCredentialsRequest } from '../../store/auth/actions'
import { fetchUsersRequest, updateUsersPagination } from '../../store/users'
import { fetchAudience, updateAudienceRequest } from '../../store/audiences'

const mapDispatchToProps = {
  updateCredentialsRequest,
  fetchUsersRequest,
  updateAudienceRequest,
  updateUsersPagination
}

const mapStateToProps = state => ({
  users: state.users,
  formValues: getFormValues('audiencesForm')(state),
  userProfiles: state.auth.user.profiles,
  isFormValid: isValid('audiencesForm')(state),
  isFetching: state.audiences.isFetching
})

const AudienceEditContainer = props => {
  const { fetchUsersRequest, userProfiles, updateAudienceRequest } = props
  const { id } = useParams()
  const history = useHistory()
  const dispatch = useDispatch()
  const [initialValues, setInitialValues] = React.useState({})
  const [renderReady, setRenderReady] = React.useState(false)
  const init = async () => {
    try {
      if (userProfiles?.includes('superuser') || userProfiles.includes('admin')) {
        fetchUsersRequest({
          filters: {
            'q[profiles_role_in]': 'investor,assessor'
          }
        })
        const response = await dispatch(fetchAudience.request({
          id,
          params: {
            'include': 'audience_users'
          }
        }))
        if (response.status === 200) {
          const { data: { data: { attributes, id }, included } } = response
          const { user_ids, ...attrs } = attributes
          const audience_users_attributes = map(included, incl => ({ id: incl.id, ...incl.attributes }))
          setInitialValues({
            ...attrs,
            audience_users_attributes,
            id
          })
        } else {
          history.push('/')
        }
      } else {
        history.push('/')
      }
    } catch (error) {
      console.error(error)
    }
  }
  React.useEffect(() => {
    init()
  }, [])

  React.useEffect(
    () => {
      if (!isEmpty(initialValues)) {
        setRenderReady(true)
      }
    },
    [initialValues]
  )
  const onSubmit = data => {
    const { id, ...attrs } = data
    const formattedData = {
      ...attrs
    }
    updateAudienceRequest({ attributes: formattedData, id, history })
  }

  if (renderReady) {
    return (
      <AudienceForm
        {...props}
        edit
        initialValues={initialValues}
        onSubmit={onSubmit}
      />
    )
  } else return null
}

export default connect(mapStateToProps, mapDispatchToProps)(
  AudienceEditContainer
)
