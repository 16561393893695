import { put, call, takeLatest, all, select } from 'redux-saga/effects'
import * as actions from './index'
import * as services from './services'

import { updateCredentialsRequest } from '../auth/actions'
import { rejectPromiseAction, resolvePromiseAction } from 'redux-saga-promise-actions'

export function * fetchAudiences (action) {
  try {
    let filters = {}
    const { payload } = action
    if (payload) {
      filters = payload.filters
    }
    const pagination = yield select(state => state.audiences.pagination)
    const response = yield call(services.fetchAudiences, { params: {
      ...filters,
      'page[number]': pagination.current,
      'page[size]': pagination.pageSize
    }
    })
    if (response.status === 200) {
      const { data: { data }, headers } = response
      const audiencesData = data.map(item => ({
        ...item.attributes,
        id: item.id
      }))
      yield put(actions.fetchAudiences.success(audiencesData))
      resolvePromiseAction(action, response)
      yield put(updateCredentialsRequest(headers))
    }
  } catch (error) {
    rejectPromiseAction(action, error)
    yield put(actions.fetchAudiences.failure(error))
  }
}

export function * createAudiences ({ payload }) {
  try {
    const { attr, history } = payload
    const params = {
      data: {
        type: 'audiences',
        attributes: {
          public: false,
          ...attr
        }
      }
    }
    const response = yield call(services.createAudiences, params)
    if (response.status === 201) {
      yield put(actions.fetchAudience.success())
      yield put(updateCredentialsRequest(response.headers))
      history.push('/audiences')
    }
  } catch (error) {
    yield put(actions.fetchAudiences.failure(error))
  }
}

function * fetchAudience (action) {
  try {
    const { payload } = action
    const { params, id } = payload
    const response = yield call(services.fetchAudience, { params, id })
    if (response.status === 200) {
      yield put(actions.fetchAudience.success(response))
      yield put(updateCredentialsRequest(response.headers))
      resolvePromiseAction(action, response)
    }
  } catch (e) {
    rejectPromiseAction(action, e)
    yield put(actions.fetchAudience.failure(e))
  }
}

function * updateAudience ({ payload }) {
  try {
    const { attributes, history, id } = payload
    const params = {
      data: {
        id,
        type: 'audiences',
        attributes: { ...attributes }
      }
    }
    const response = yield call(services.updateAudience, { params, id })
    if (response.status === 200) {
      yield put(actions.updateAudienceSuccess())
      yield put(updateCredentialsRequest(response.headers))
      history.push('/audiences')
    }
  } catch (e) {
    yield put(actions.updateAudienceError())
  }
}

export function * deleteAudience ({ payload }) {
  try {
    const response = yield call(services.deleteAudiences, payload)
    yield put(updateCredentialsRequest(response.headers))
    yield put(actions.fetchAudiences.request())
  } catch (e) {
    console.error(e)
  }
}

export function * watchFetchAudiences () {
  yield takeLatest(actions.fetchAudiences.request, fetchAudiences)
}

export function * watchCreateAudiences () {
  yield takeLatest(actions.createAudienceRequest, createAudiences)
}

export function * watchUpdateAudiences () {
  yield takeLatest(actions.updateAudienceRequest, updateAudience)
}

export function * watchFetchAudience () {
  yield takeLatest(actions.fetchAudience.request, fetchAudience)
}

export function * watchDeleteAudiences () {
  yield takeLatest(actions.deleteAudienceRequest, deleteAudience)
}

export function * AudiencesSagas () {
  yield all([
    watchFetchAudiences(),
    watchCreateAudiences(),
    watchUpdateAudiences(),
    watchFetchAudience(),
    watchDeleteAudiences()
  ])
}
