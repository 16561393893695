import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import btoa from 'btoa'
import { load } from '@fingerprintjs/fingerprintjs'

import {
  SIGN_IN as signIn,
  SIGN_IN_SUCCESSFUL as signSuccess,
  VERIFY_BROWSER_SUCCESS as verifyBrowserSuccess,
  RESET_SIGN_IN_ERROR as resetError,
  updateCredentialsRequest,
  updateDeviceId
} from '../../store/auth/actions'

import LoginComponent from '../../components/form/LoginForm'
import DeviceAuthComponent from '../../components/view/DeviceAuthView'

const mapStateToProps = ({ auth }) => ({
  isLogged: auth.isLogged,
  isFetching: auth.isFetching,
  isError: auth.isError,
  errorMsg: auth.errorMsg,
  deviceId: auth.deviceId
})

const mapDispatchToPros = {
  signIn,
  signSuccess,
  verifyBrowserSuccess,
  updateCredentialsRequest,
  resetError,
  updateDeviceId
}
export const LoginPage = props => {
  const [stage, setStage] = React.useState(0)
  const { signIn, isLogged, resetError, updateDeviceId, ...rest } = props
  React.useEffect(async () => {
    const fp = await load()
    const result = await fp.get()
    updateDeviceId(result.visitorId)
    resetError()
    return () => resetError()
  }, [])
  React.useEffect(
    () => {
      if (isLogged) {
        setStage(1)
      }
    },
    [isLogged]
  )

  const onSubmit = data => {
    const formatedData = {
      ...data,
      password: btoa(data['password'])
    }
    signIn(formatedData)
  }

  return stage === 0 ? (
    <LoginComponent onSubmit={onSubmit} {...rest} />
  ) : (
    <DeviceAuthComponent setStage={setStage} {...rest} />
  )
}

LoginPage.propTypes = {
  signIn: PropTypes.func.isRequired,
  verifyBrowserSuccess: PropTypes.func,
  isLogged: PropTypes.bool,
  isFetching: PropTypes.bool,
  resetError: PropTypes.func,
  updateDeviceId: PropTypes.func
}

LoginPage.defaultProps = {
  loginValues: undefined,
  isLogged: false,
  isFetching: false,
  verifyBrowserSuccess: () => {}
}

export default connect(mapStateToProps, mapDispatchToPros)(LoginPage)
