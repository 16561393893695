import React from 'react'
import Checkbox from 'antd/lib/checkbox/Checkbox'

const CheckboxInput = (props) => {
  const { input, placeholder, disabled } = props
  const { value, name, ...rest } = input
  return (
    <div className='checkbox' id={`input-${input.name}`}>
      <span>{placeholder}</span>
      <Checkbox
        value={value}
        name={name}
        disabled={disabled}
        checked={value}
        {...rest}
      />
    </div>
  )
}

export default CheckboxInput
