import { setQueryParams } from '../../utils/functions'
import http from '../../utils/http'

const audiencesEndpoint = '/audiences'

export const fetchAudiences = ({
  params
}) => {
  const querystring = setQueryParams(params)
  let endpoint = `${audiencesEndpoint}/?${querystring}`
  return http
    .get(endpoint)
    .then(res => res)
    .catch(err => err.response)
}

export const fetchAudience = ({ params, id }) => {
  const querystring = setQueryParams(params)
  let endpoint = `${audiencesEndpoint}/${id}?${querystring}`

  return http
    .get(endpoint)
    .then(res => res)
    .catch(err => err.response)
}

export const createAudiences = params => {
  return http
    .post(audiencesEndpoint, params)
    .then(res => res)
    .catch(err => err.response)
}

export const updateAudience = ({ params, id }) => {
  return http
    .put(`${audiencesEndpoint}/${id}`, params)
    .then(res => res)
    .catch(err => err.response)
}

export const deleteAudiences = id => {
  return http({
    url: `${audiencesEndpoint}/${id}`,
    headers: {
      'Content-Type': 'application/vnd.api+json'
    },
    method: 'DELETE',
    data: {}
  })
}
