import { createAction, createReducer } from '@reduxjs/toolkit'
import { createPromiseAction } from 'redux-saga-promise-actions'

export const fetchAudiences = createPromiseAction(
  'audiences/FETCH_AUDIENCES'
)()

export const createAudienceRequest = createAction(
  'audiences/CREATE_AUDIENCE_REQUEST'
)

export const createAudienceSuccess = createAction(
  'audiences/CREATE_AUDIENCE_SUCCESS'
)

export const createAudienceError = createAction(
  'audiences/CREATE_AUDIENCE_ERROR'
)

export const updateAudienceRequest = createAction(
  'audiences/UPDATE_AUDIENCES_REQUEST'
)

export const updateAudienceSuccess = createAction(
  'audiences/UPDATE_AUDIENCES_SUCCESS'
)

export const updateAudienceError = createAction(
  'audiences/UPDATE_AUDIENCES_ERROR'
)

export const fetchAudience = createPromiseAction(
  'audiences/FETCH_AUDIENCE'
)()

export const deleteAudienceRequest = createAction(
  'audiences/DELETE_AUDIENCE_REQUEST'
)

export const updateAudiencesPagination = createAction(
  'audiences/UPDATE_AUDIENCES_PAGINATION'
)

export const initialState = {
  items: [],
  pagination: {
    current: 1,
    defaultPageCurrent: 1,
    defaultPageSize: 20,
    pageSize: 20,
    pageSizeOptions: [5, 10, 20],
    showSizeChanger: true
  },
  isFetching: false,
  error: null
}

export const AudiencesReducer = createReducer(initialState, {
  [fetchAudiences.request]: (state, action) => ({
    ...state,
    isFetching: true,
    error: null
  }),
  [fetchAudiences.success]: (state, action) => ({
    ...state,
    isFetching: false,
    items: action.payload
  }),
  [fetchAudiences.failure]: (state, action) => ({
    ...state,
    isFetching: false,
    error: action.payload
  }),
  [createAudienceRequest]: (state, action) => ({
    ...state,
    isFetching: true,
    error: null
  }),
  [createAudienceSuccess]: (state, action) => ({
    ...state,
    isFetching: false,
    items: action.payload
  }),
  [createAudienceError]: (state, action) => ({
    ...state,
    isFetching: false,
    error: action.payload
  }),
  [updateAudienceRequest]: (state, action) => ({
    ...state,
    isFetching: true,
    error: null
  }),
  [updateAudienceSuccess]: (state, action) => ({
    ...state,
    isFetching: false,
    items: action.payload
  }),
  [updateAudienceError]: (state, action) => ({
    ...state,
    isFetching: false,
    error: action.payload
  }),
  [fetchAudience.request]: (state, action) => ({
    ...state,
    isFetching: true,
    error: null
  }),
  [fetchAudience.success]: (state, action) => ({
    ...state,
    isFetching: false
  }),
  [fetchAudience.failure]: (state, action) => ({
    ...state,
    isFetching: false,
    error: action.payload
  }),
  [updateAudiencesPagination]: (state, action) => ({
    ...state,
    pagination: {
      ...state.pagination,
      ...action.payload
    }
  })
})
